<template>
  <div class="table-container tab-container">
    <overlay-loader :loading="$apollo.queries.users.loading"/>
    <hcc-table
      :actualPage="page"
      :rowsPerPage="10"
      :totalPages="total"
      :pagination="total > 10"
      :columns="userColumns"
      :rows="users"
      :enableAdd="licences || isOwner"
      @search="searchUser"
      @add="addNewUser"
      @edit="editUser"
      @delete="deleteUser"
      @pageChanged="pageChanged"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'campaigns'">
          <div v-if="props.row.campaigns.length === 0">
            <span class="no-campaign">{{ $t("users['no-campaigns']") }}</span>
          </div>
          <div v-if="props.row.campaigns.length === 1">
            <span>{{ props.row.campaigns[0].name }}</span>
          </div>
          <div
            @click.stop="() => {}"
            v-else-if="props.row.campaigns.length > 1"
          >
            <hcc-dropdown
              custom
              :position="getDropdownPosition(props.row.originalIndex)"
            >
              <template #button>
                <hcc-button variant="outline" color="muted" size="sm">{{
                  $t("users['show-campaigns']")
                }}</hcc-button>
              </template>
              <hcc-dropdown-item
                unclickable
                v-for="campaign in props.row.campaigns"
                :key="campaign.id"
              >
                {{ campaign.name }}
              </hcc-dropdown-item>
            </hcc-dropdown>
          </div>
        </span>
        <span v-else-if="props.column.field == 'roles'">
          {{ $t(`roles.${props.row.roles.name}`)  }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <user-actions
      :userCompany="companyId"
      :edit="isEdit"
      :user="infoUser"
    />
    <hcc-confirmation />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';
import deleteUser from '@/graphql/mutations/deleteUser.gql';
import listCompanyUsers from '@/graphql/queries/users/listByCompanyId.gql';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import EventBus from '@/eventBus';
import errorHandler from '@/utils/error.handler';
import { UPDATE_COMPANY_USERS } from '@/eventTypes';

export default {
  props: {
    filters: {
      type: Object,
    },
    isOwner: {
      required: true,
      type: Boolean,
    },
    licences: {
      type: Boolean,
    },
    role: {
      type: Object,
    },
  },
  components: {
    OverlayLoader,
    UserActions: () => import('@/components/users/UserActions.vue'),
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccDropdown: () => import('@/components/shared/HccDropdown/index.vue'),
    HccButton: () => import('@/components/shared/HccButton/index.vue'),
    HccDropdownItem: () => import('@/components/shared/HccDropdown/DropdownItem.vue'),
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
  },
  data() {
    return {
      page: 1,
      text: null,
      infoUser: {},
      total: 0,
      isEdit: false,
    };
  },
  created() {
    EventBus.$on(UPDATE_COMPANY_USERS, () => {
      this.$apollo.queries.users.refresh();
    });
  },
  watch: {
    companyId() {
      this.page = 1;
    },
    campaign() {
      this.page = 1;
    },
    role() {
      this.page = 1;
    },
  },
  computed: {
    ...mapGetters({
      user: 'shared/userInfo',
      roles: 'shared/getRoles',
    }),
    userColumns() {
      return [{
        label: this.$t('common.name'),
        field: 'name',
      }, {
        label: this.$t('common.email'),
        field: 'email',
      }, {
        label: this.$t('app.campaigns'),
        field: 'campaigns',
      }, {
        label: 'Role',
        field: 'roles',
      }];
    },
    emptyRows() {
      return this.total === 0;
    },
    fullRoles() {
      return this.roles.map(({ name }) => name);
    },
    companyId() {
      return this.filters ? this.filters.companyId : null;
    },
    campaign() {
      return this.filters ? this.filters.campaignId : null;
    },
  },
  apollo: {
    users() {
      return {
        query: gql`${listCompanyUsers}`,
        skip() {
          return !this.companyId;
        },
        fetchPolicy: 'network-only',
        variables() {
          return {
            companyId: this.companyId,
            campaign: this.campaign ? this.campaign : undefined,
            roles: this.role.name ? this.role.name : this.fullRoles,
            page: this.page,
            perPage: 10,
            text: this.text,
          };
        },
        update({ listCompanyUsers: usersPage }) {
          const { users, total } = usersPage;
          this.total = total;
          return users.map(user => this.mapUser(user));
        },
        error(err) {
          errorHandler.logErrors(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        },
      };
    },
  },
  methods: {
    pageChanged(page) {
      this.page = page;
    },
    mapUser(user) {
      const campaigns = user.campaigns != null
        ? user.campaigns.map(c => ({ ...c, $isDisabled: true }))
        : [];
      return { ...user, campaigns };
    },
    deleteUser(props) {
      this.$modal.show('confirmation', {
        title: this.$t('configuration.delete-user'),
        description: this.$t('configuration.confirmation'),
        variant: 'error',
        confirm: async () => {
          if (props.campaigns.length !== 0) {
            this.$toasted.global.success(this.$t('users.errors.campaign-association-warning'));
          }
          await this.$apollo.mutate({
            mutation: deleteUser,
            variables: {
              userId: props.id,
              companyId: props.company,
            },
          });
          this.page = 1;
          this.$apollo.queries.users.refresh();
        },
      });
    },
    editUser(props) {
      if (this.validUser(props.id)) {
        this.isEdit = true;
        this.infoUser = props;
        this.$modal.show('Usuario');
      } else {
        this.$toasted.global.error(this.$t('users.errors.edit-user'));
      }
    },
    addNewUser() {
      this.isEdit = false;
      this.$modal.show('Usuario');
    },
    async searchUser({ value }) {
      this.text = value;
    },
    validUser(id) {
      return this.user.id !== id;
    },
    getDropdownPosition(index) {
      return index === this.total - 1 ? 'top-right' : 'bottom-right';
    },
  },
};
</script>

<style scoped lang='scss'>
::v-deep .campaign {
  width: 200px;
}

.tab-container{
    position: relative;
  }

.no-campaign {
  font-weight: bold;
  color: $gray-disabled;
}
</style>
